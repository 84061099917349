import React, { useState } from "react"
import { useAuth0,withAuthenticationRequired } from "@auth0/auth0-react"

const AuthTest = () => {

  const { isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    return <div>User is logged in</div>;
  } else {
    return <div>User is not logged in</div>;
  }
}

export default AuthTest
/* 👇 Wrap the component in the withAuthenticationRequired handler 👇 */
//export default withAuthenticationRequired(AuthTest, {
//  onRedirecting: () => {
//    window.location.href = "http://localhost:8000/login";
//    return null; // Return null to prevent the component from rendering while the user is being redirected
//  },
//});
